import React from "react";
import LCartGlobalContext from "../../context/cartGlobalContext";
import { NavBar } from "../navbar/navbar";
import Footer from "../footer/footer";
import * as StyledComponents from "./layout.module.scss";

//React-redux
import { createStore } from "redux";
import rootReducer from "../redux/reducers";
import { Provider } from "react-redux";

const store = createStore(
  rootReducer
  //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

//Markup
export const PageLayout = ({ children }) => {
  return (
    <LCartGlobalContext>
      <Provider store={store}>
        <NavBar />
        <main className={StyledComponents.main}>{children}</main>
        <Footer />
      </Provider>
    </LCartGlobalContext>
  );
};
